.bg-box {
  background-color: #f2f2f2;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}
.shadow-none {
  box-shadow: none;
}
.box-header {
  text-align: center;
  height: auto;
  color: white;
  font-size: 1.75rem;
  padding-block: 2.19rem;
  font-weight: normal;
  margin: 0 auto;
  vertical-align: middle;
}
.box-header > * {
  margin: 0;
}
@media (max-width: 768px) {
  .box-header {
    padding-block: 1rem;
  }
}
.box-subheader {
  text-align: left;
  height: auto;
  color: white;
  font-size: 1.5rem;
  padding-block: 0.7rem;
  font-weight: normal;
  margin: 0 auto;
  vertical-align: middle;
}
.box-content {
  margin: 0 auto;
  vertical-align: middle;
  padding: 2.1rem;
  padding-bottom: 0;
  /* background: grey; */
  font-size: 20px;
}
.table {
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
}
.table-responsive > .table-bordered > tbody > tr,
.table-bordered td,
.table-bordered th {
  border: solid rgba(0, 0, 0, 0.1);
}
.table-responsive > .table-bordered > tbody > tr,
.table-bordered td {
  border-width: 1px;
}
td > td,
td > tr,
td > tr > td {
  border: none;
}
.table-responsive > .table-bordered thead tr:first-child th {
  border-top: none;
}
.table-responsive > .table-bordered td:nth-child(1),
.table-responsive > .table-bordered th:nth-child(1) {
  border-left: none;
}
.table-responsive > .table-bordered td:last-child,
.table-responsive > .table-bordered th:last-child {
  border-right: none;
}
#individual_report .table > thead > tr > th:only-of-type {
  font-size: 1.8em;
  font-weight: normal;
  color: #397adb;
  border-top: none;
}
.rounded-xl {
  border-radius: 1rem !important;
}
.rounded-xxl {
  border-radius: 1.5rem !important;
}
.prevent-overflow {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
#individual_report {
  align-self: normal;
}
#individual_report .table th > div {
  width: 100%;
  display: flex;
  align-items: center;
}
td.center,
th.center {
  text-align: center;
}
.bg-box input {
  background-color: transparent;
  border: 1.34px solid rgba(0, 0, 0, 0.5);
}
.table-inner td:empty {
  display: none;
}
.authorised_domain_name_container > .table {
  margin: 0 auto;
  width: 80%;
}
.authorised_domain_name_container > .table > tr:first-child {
  color: #397adb;
}
.authorised_domain_name_container > .table > tr > *:nth-child(2) {
  width: min-content;
}
.authorised_domain_name_container > .table > tr {
  font-size: 1.5rem;
  font-weight: normal;
  color: black;
}
.authorised_domain_name_container .switch > .slider {
  scale: 1.3;
}
.authorized_domain_name_footer,
.remove_domain_footer {
  padding-inline: 15%;
  text-align: end;
}
.authorized_domain_name_footer > button,
.remove_domain_footer > button {
  box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.2);
  min-width: max-content;
  max-width: 40%;
  padding-inline: 2rem;
  padding-block: 0.5rem;
  font-size: 1.4rem;
}
.remove_domain_wrapper > .box-content {
  color: red;
  font-size: 1.5rem;
  width: 80%;
}
.authorized_domain_name_footer > button {
  background-color: #3a64a2;
}
.remove_domain_footer > button {
  background: #f00 !important;
}

.domain_report_table_wrapper table td {
  vertical-align: middle;
}

.sub-table {
  font-size: 0.8rem;
}
.sub-table th {
  border-width: 1px;
}

.color-box {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.2rem;
}
/* userinterface.js line 661 Piechart colors*/
.color-box.green {
  background-color: green;
}
.color-box.red {
  background-color: #f00;
}
.color-box.sky {
  background-color: #add8e6;
}
.isLink {
  color: rgba(0, 0, 0, 0.8);
  fill: rgba(0, 0, 0, 0.8);
  font-size: 22px;
  text-decoration: none;
}
.isLink > .nav-link-text {
  color: inherit;
  text-decoration: inherit;
}
.isLink:hover {
  color: green;
  fill: green;
  text-decoration: underline;
}
.isLink[disabled] {
  pointer-events: none;
  color: #a2a2a2;
  fill: #a2a2a2;
}
.isLink.active {
  color: #397adb;
  fill: #397adb;
}
.isLink > svg {
  fill: inherit;
}
.sub-menu-parent-displayed > .isLink {
  font-size: 18px;
}
.page-wrapper {
  display: flex;
}
.page-container {
  max-width: 88%;
  margin-inline: auto;
  padding-inline: 40px;
}
@media screen and (max-width: 574px) {
  .page-wrapper {
    flex-direction: column;
  }
  .page-container {
    padding-inline: 0;
  }
}
