/* .body-content{
    display:block
} */

.text-over-image {
  width: 400px;
  position: absolute;
  top: 155px;
  left: 10%;
  background: #3a64a2c4;
  color: white;
  padding: 10px;
  text-align: JUSTIFY;
}

.header-dashboard-text {
  margin-left: auto;
  color: white;
  margin-right: 20px;
}
a.header-dashboard-text:hover {
  color: white;
}

.home-page-image {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.home-page-image img {
  border-radius: 100px;
}

.search-form {
  display: flex;
  flex-direction: row;
  margin: 30px auto;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
}

.search-form span {
  /* flex:1 auto;
    display: inline-block; */
  font-size: 1.5rem;
  font-family: Roboto;
}

.search-form input {
  /* flex: 3 auto;
    width: 0px; */
  padding: 10px;
  margin: 10px;
  border: 0px;
  border-bottom: 2px solid #3a64a2;
}

.search-form input:focus {
  outline: none;
}

.dmarc-header {
  text-align: center;
}

.table-dmarc {
  width: 70%;
  margin: 0 auto;
  font-size: 0.8rem;
}
.table-mxserver {
  width: 70%;
  margin: 0 auto;
  font-size: 0.8rem;
  margin-bottom: 40px;
}

.table-dkim {
  width: 70%;
  margin: 0 auto;
  font-size: 0.8rem;
  table-layout: fixed;
  margin-bottom: 30px;
  word-break: break-all;
}

.table-dkim th {
  word-break: break-all;
}

.record-header {
  text-align: center;
  font-size: 23px;
  background: #66b9ff;
  color: white;
  font-weight: 800;
}

.record-container {
  text-align: left;
  margin: 40px 20px;
  width: 70%;
  margin: 0 auto;
}

.inline-text-positive {
  background: green;
  padding: 7px;
  margin: 10px 0px;
  color: white;
  margin-right: 5px;
  border-radius: 10px;
  text-align: center;
}
.inline-text-negative.text {
  background: red;
  padding: 7px;
  margin: 10px 0px;
  color: white;
  margin-right: 5px;
  border-radius: 10px;
  text-align: center;
}

.entry.record-text {
  background: grey;
  color: white;
  font-size: 12px;
  /* width: 70%; */
  text-align: justify;
  padding: 10px;
}

.entry.duplicates {
  /* background: #ff0000b5; */
  color: red;
  padding: 10px;
}

.parent-domain {
  background: grey;
  color: white;
  margin-top: 5px;
}

.parent_domain .warning {
  padding: 10px;
  color: red;
}

.depth-number {
  color: green;
}

.depth-number-warning {
  padding: 5px;
  background: red;
  color: white;
  /* border-radius: 50%; */
  margin: 10px;
}
.dkim-error {
  margin: 20px;
  color: red;
}

.inline-text-negative.number {
  border: 2px solid;
  padding: 4px;
  border-radius: 10px;
  background: red;
  color: white;
  margin: 10px;
  display: inline-block;
  width: 40px;
  text-align: center;
}

.duplicates-ip {
  text-align: center;
}

.duplicates-ip span {
  color: white;
  background-color: grey;
  text-align: center;
  font-size: 1.5rem;
  padding: 5px;
}

.parent-length {
  border: 2px solid;
  padding: 4px;
  border-radius: 10px;
  background: green;
  color: white;
  margin: 10px;
  display: inline-block;
  width: 40px;
  text-align: center;
}

.entry.child-container {
  margin-left: 15px;
  margin-bottom: 10px;
  padding: 15px;
}

.child_domain.name,
.entry .single.name {
  background: #00748099;

  color: white;
  padding: 5px;
}

.child_domain.ip,
.entry .single.ip {
  margin-left: 30px;
  list-style: none;
}

.spf_record {
  background: grey;
  color: white;
  font-size: 12px;
  /* width: 70%; */
  text-align: justify;
  padding: 10px;
}

.child_domain.ip li,
.entry .single.ip li {
  margin: 10px;
  /* background: grey;
    width: 30%;
    text-align: center;
    color: white;
    padding: 5px;
    height: 50px */
  border-bottom: 2px solid;
}

.single.name span {
  border: 2px solid;
  padding: 4px;
  border-radius: 10px;
  background: green;
  color: white;
  margin: 10px;
  display: inline-block;
  width: 40px;
  text-align: center;
}

.entry {
  margin-bottom: 20px;
  margin-top: 10px;
}

.depth-number {
  padding: 10px;
  background: green;
  color: white;
  border-radius: 50%;
  margin: 10px;
}

.dmarc-error,
.spf-error {
  color: white;
  background: red;
  margin: 20px;
  width: 40%;
  text-align: center;
  margin: 20px auto;
  padding: 5px;
}

/* .entry.duplicates .duplicates-ip{
    background-color: #ff0000b5 ;
} */

.duplicates-ip li {
  background: grey;
  width: 200px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 5px;
  list-style: none;
  margin-top: 20px;
  color: white;
}

.collapsible.collapsed {
  display: none;
}

.expander-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.expander-button {
  background: #66b9ff;
  padding: 10px;
  color: white;
  border-radius: 8px;
  box-shadow: 2px 3px 4px green;
  font-weight: 800;
}

.collapser-button {
  background: #66b9ff;
  padding: 10px;
  color: white;
  border-radius: 8px;
  box-shadow: 2px 3px 4px red;
  font-weight: 800;
}

.email-servers-container {
  margin: 20px;
}

.email-servers-container button {
  margin: 10px;
}

.server-actual-response {
  background: grey;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 40px;
}
.email-server-list-headers {
  background: green;
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  color: white;
  font-size: 1.2rem;
}

.emailserver-response-loader {
  margin-bottom: 40px;
}

.emailserver-error {
  margin-bottom: 40px;
  color: red;
  width: 70%;
  margin: 20px auto 40px;
  border: 1px solid;
  padding: 10px;
}

.response-emailserver.single {
  display: block;
  margin: 10px;
  color: wheat;
  font-size: 0.8rem;
}

@media (max-width: 767px) {
  .text-over-image {
    width: 400px;
    position: absolute;
    top: 155px;
    left: 10%;
    background: #3a64a2c4;
    color: white;
    padding: 10px;
  }
}
@media (max-width: 576px) {
  .text-over-image {
    width: 100%;
    background: #3a64a2c4;
    color: white;
    padding: 10px;
    position: relative;
    top: -20px;
    left: 0px;
  }

  .home-page-image {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .search-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .search-form input {
    width: 80%;
  }
  .header-dashboard-text {
    margin-left: auto;
    color: white;
    margin-right: 20px;
    font-size: 12px;
  }
}
